/* SiFlow.css */

.siflow-container {
  background-color: #141414;
  color: gainsboro;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.61);
  width: 100%;
  overflow-x: auto;
  padding-top: 0px;
}

.siflow-container h1 {
  color: gainsboro;
  text-align: center;
  margin-bottom: 20px;
}

.table-container {
  overflow-x: auto;
  width: 98%;
}

.siflow-table {
  width: 100%;
  border-collapse: collapse;
  background-color: #1a1a1a;
  color: gainsboro;
  font-size: 0.9em;
}

.siflow-table th,
.siflow-table td {
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #252830;
  white-space: nowrap;
}

.siflow-table th {
  background-color: #222222;
  font-weight: bold;
}

.siflow-table tr:hover {
  background-color: #252830;
}

.loading,
.error {
  text-align: center;
  padding: 20px;
  font-size: 18px;
  color: gainsboro;
}

.error {
  color: #fc1e1e;
}

.load-more-button {
  background-color: #1396d6;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  font-weight: bold;
  font-size: 14px;
  display: block;
  margin: 20px auto 0;
  transition: background-color 0.3s ease;
}

.load-more-button:hover {
  background-color: #4fa3cc;
}

