.ref-links-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 93vw;
  margin: auto;
}

.ref-links-section {
  width: 100%;
  max-width: 100%;
  background-color: #141414;
  border-radius: 5px;
}

.ref-links-section h4 {
  white-space: pre-wrap;
}

.ref-links-section .ticker:nth-child(2) h4 {
  margin-bottom: 40px;
}

.ref-links-section h3 {
  margin-top: 0;
}

.ref-links-section ul {
  list-style-type: none;
  padding: 0;
}

.ref-links-section .animated-text {
  text-decoration: none;
  color: gainsboro;
}

.ref-links-section .animated-text:hover {
  color: #ffffff;
}

.ref-links-section li {
  cursor: pointer;
  padding: 8px 0;
  transition: background-color 0.3s;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ref-links-section li:hover {
  background-color: #4141417e;
  border-radius: 4px;
}

.address-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  margin-left: 15px;
}

.animated-text {
  font-family: monospace;
  font-size: 16px;
  animation: none;
  text-align: left;
}

.copy-button {
  background-color: transparent;
  border: none;
  color: rgb(136, 136, 136);
  font-size: 16px;
  cursor: pointer;
  padding: 7px 15px;
  margin-left: 5px;
  margin-bottom: 5px;
}

.copy-button:hover {
  background-color: #c5c5c5;
  color: #1f1f1f;
}

.notification-popup {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(30, 143, 255, 0.945);
  color: #fff;
  padding: 0px 10px;
  border-radius: 5px;
  z-index: 999;
  animation: fadeIn 0.3s ease-in-out, fadeOut 0.3s ease-in-out 2.7s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateX(-50%) translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateX(-50%) translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(20px);
  }
}

@media screen and (max-width: 800px) {
  .ref-links-section li {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .address-container {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .animated-text {
    font-size: small;
    margin-left: -13px;
  }

  .copy-button {
    font-size: 14px;
    padding: 5px 10px;
  }
}