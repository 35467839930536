.footer {
  background-color: #141414;
  color: gainsboro;
  padding: 40px 0;
  font-size: 16px;
  max-width: 93vw;
  margin: auto;
}

.footer-content {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap to the next line */
  justify-content: center;
  max-width: 1000px;
  margin: 0 auto;
  text-align: center; /* Center text in all footer sections */
}

.footer-section {
  flex: 1;
  margin: 0 15px; /* Adjusted margin */
}

.footer-logo-column {
  flex: 1;
  margin: 0 30px; /* Increased margin to 30px */
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the content */
  margin-right: 80px; /* Added negative margin to align the content with other sections */
}

.footer-logo {
  max-height: 100px;
  margin-bottom: 20px;
  margin-top: 23px;
}

.social-icons {
  display: flex;
  justify-content: center; /* Center the social icons */
}

.social-icons button {
  color: gainsboro;
  font-size: 34px;
  margin: 0 20px 0 0; /* Remove right margin, add left margin */
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s;
  padding: 0px;
}

.social-icons button:hover {
  color: #ffffff;
}

.footer-section h4 {
  font-size: 18px;
  margin-bottom: 15px;
  text-align: center; /* Center the heading */
}

.footer-section ul {
  list-style-type: none;
  padding: 0;
  text-align: center; /* Center the list items */
}

.footer-section ul li {
  margin-bottom: 14px;
}

.footer-section ul li button {
  color: gainsboro;
  text-decoration: none;
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s;
  padding: 0px;
  font-size: 14px;
  font-weight: lighter;
}

.footer-section ul li button:hover {
  color: #ffffff;
}

.footer-bottom {
  margin-top: 30px;
  text-align: center;
}

.footer-bottom p {
  font-size: 14px;
  color: #8c8c8c;
}

/* Media Query for smaller screens */
@media (max-width: 768px) {
  .footer-content {
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center items */
  }

  .footer-section {
    margin: 8px 0; /* Adjusted margin for smaller screens */
  }

  .footer-logo-column {
    margin-right: 0; /* Remove negative margin */
    margin-left: 0;
  }

  .social-icons button {
    margin: 0 10px 10px 0; /* Adjusted margin for smaller screens */
  }
}
