.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #141414;
  color: gainsboro;
  padding: 5px;
  max-width: 93vw;
  margin: auto;
  margin-bottom: 10px;
  position: relative;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-logo img {
  height: 50px;
}

.navbar-center {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.navbar-center button {
  padding: 11px 24px;
  margin: 0px;
}

@media (max-width: 800px) {
  .navbar-center button {
    padding: 10px 20px; /* Reduce padding for smaller screens */
  }

  .user-email {
    display: none; /* Hide user email on smaller screens */
  }
}

@media (max-width: 500px) {
  .navbar-center button {
    padding: 8px 14px; /* Reduce padding for smaller screens */
    font-size: 14px; /* Reduce font size for smaller screens */
  }

  .navbar-logo img {
    height: 8vw;
  }

  .navbar-right .user-info .user-picture {
    font-size: 28px; /* Reduce the icon size for smaller screens */
  }
}

.navbar-right {
  display: flex;
  align-items: center;
  position: relative;
}

.user-info {
  display: flex;
  align-items: center;
}

.user-picture {
  font-size: 36px;
  color: gainsboro;
  cursor: pointer;
  margin-right: 8px; /* Add some spacing between icon and email */
}

.user-email {
  margin-right: 10px;
}

.user-picture:hover {
  color: white;
  background-color: #252525;
  /* make the background shadow a circle */
  border-radius: 50%;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #181818;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  z-index: 1;
  display: none;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-menu button {
  background-color: transparent;
  color: gainsboro;
  border: none;
  padding: 10px 16px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  font-weight: normal;
}

.dropdown-menu button:hover {
  background-color: #252525;
}

.navbar-button {
  background-color: #1a1a1a;
  color: gainsboro;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 5px;
}

.navbar-button:hover {
  background-color: #252525;
}

.navbar-button.active {
  background-color: #3f51b5;
  color: white;
}