/* styles.css */

body {
  font-family: 'Arial', sans-serif;
  padding: 0;
  background-color: #141414; 
  color: gainsboro; 
  display: flex;
  justify-content: center;
  min-height: 100vh;
}

.container {
  max-width: 93vw;
  min-width: 93vw; /* Changed max-width */
  margin: auto;
  /* padding: 20px; */
  border: 1px solid #252830;
  border-radius: 5px;
  margin-top: 0px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.61);
  background-color: #141414; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: gainsboro;
}

h1 {
  color: gainsboro;
  text-align: center;
  margin-bottom: 20px;
}

span {
  color: gainsboro;
  margin: 10px;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  align-items: center;
  /* *** */
  min-width: fit-content; /* Determines width of form once edit button is clicked */
  /* *** */
}

.position-form-container {
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.position-form-container form {
  width: 100%;
  max-width: 400px; /* Adjust the maximum width as needed */
}

.position-form-container label {
  width: 100%;
  text-align: left;
}

.position-form-container input,
.position-form-container select {
  width: 100%;
}

label {
  margin-bottom: 10px;
  font-weight: bold;
  color: gainsboro; /* Changed label color */
}

input {
  width: 30vw;
  padding: 10px;
  margin-bottom: 10px; /* Adjusted margin-bottom */
  box-sizing: border-box;
  border-radius: 3px;
  border: 1px solid #ddd;
  background-color: #f8f8f8;
  color: #555; /* Changed input text color */
}

button {
  background-color: #1396d6;
  color: white;
  padding: 15px 80px;
  border: none;
  cursor: pointer;
  border-radius: 3px;
  font-weight: bold;
  font-size: 16px;
  display: block;
  margin: auto;
  margin-top: 10px;
}

button:hover {
  background-color: #4fa3cc;
}

#portfolio-section {
  background-color: #141414; 
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.205);
  margin-top: 10px;
  display: inline-block; /* Change display to inline-block */
}

/* Ensure asset class columns are displayed in a row */
#portfolio-section {
  display: flex;
  flex-wrap: wrap; /* Wrap columns if there's not enough room */
  justify-content: center; /* Center columns horizontally */
  min-width: 90%;
  max-width: 95%;
}

.asset-class-columns {
  display: flex;
  flex-direction: row; /* Arrange columns horizontally */
  flex-wrap: wrap; /* Wrap columns if there's not enough room */
  justify-content: center; /* Center columns horizontally */
  margin-top: 20px;
}

.asset-class-column {
  flex: 1; /* Each column takes equal width */
  margin: 0 10px; /* Add margin between columns */
}

.asset-class-column-header {
  margin-top: 20px;
}

.exchange-column {
  flex: 1;
  max-width: 95%;
  margin: 20px;
  margin-top: -10px;
}

.exchange-column-header {
  background-color: #222222;
  color: #fff;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 5px; /* Radius of the corners */
    transition: box-shadow 0.3s ease; /* Add a transition for the box-shadow */
}

.exchange-column-header:hover {
  background-color: #4141417e; /* Change this to the desired background color for the span on hover */
}

@media (max-width: 800px) {
  #portfolio-section {
    flex-direction: column; /* Change to a single column layout */
    align-items: center; /* Center columns vertically */
  }

  .asset-class-column {
    max-width: 90%; /* Each column takes full width */
    min-width: 80%;
  }

  .exchange-column {
  max-width: 100%; /* Increase the maximum width */
  min-width: 90%;
  margin-bottom: 40px;
  }
}

.position-container {
  box-shadow: 0 0 20px #3e94c5;
  margin-bottom: 10px;
  border-radius: 0px;
  /* display: flex; */
  /* justify-content: space-between; */
  /* align-items: center; */
  position: relative;
}

.position-container.long {
  box-shadow: 0 0 13px #02f022e0; /* Green color for long positions */
}

.position-container.short {
  box-shadow: 0 0 13px #fc1e1e; /* Red color for short positions */
}

.position button {
  position: absolute;
  right: 0;
  /* Adjust top/bottom/left/right as needed to position the button */
}

.position {
  display: flex;
  height: 40px;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #1a1a1a;
}

.position p {
  margin: 0;
  white-space: nowrap;
  /* margin-right: 20px; */
}

.position + .position {
  margin-top: 15px;
  box-shadow: 0 0 20px #4fa3cc;
}

.remove-button {
  background-color: #ffffff00;
  color: rgb(255, 0, 0);
  border: none;
  /* width: 40px; */
  /* padding: 8px 12px; */
  cursor: pointer;
  /* border-radius: 4px; */
  padding: 2px 6px; /* Adjust padding to change button size */
  font-size: 11px;
  font-weight: light;
  margin-right: 2px;
  margin-top: 39px;
}

.remove-button:hover {
  color: #ffffff; /* Change to your desired hover color */
  background-color: #fc2424a8
}

.sort-button {
  background-color: #141414; 
  color: gainsboro;
  padding: 10px 15px; /* Adjusted padding for smaller button */
  border: none;
  cursor: pointer;
  border-radius: 3px;
  font-weight: bold;
  font-size: 14px; /* Adjusted font size */
  margin: 0;
  margin-top: 10px;
  margin-right: 3%;
  align-self: flex-end; /* Align button to the right */
}

.sort-button:hover {
  background-color: #1f1f1f; 
}

.edit-button {
  background-color: #ffffff00;
  color: #1396d6;
  border: none;
  cursor: pointer;
  padding: 2px 7px; /* Adjust padding to change button size */
  font-size: 11px;
  font-weight: light;
  margin-right: 22px;
  margin-top: 41px;
}

.edit-button:hover {
  color: #ffffff; /* Change to your desired hover color */
  background-color: #19aaf3d2
}

.edit-form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-form label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 70%;
  margin-bottom: 10px;
}

.edit-form input,
.edit-form select {
  width: 100%;
  max-width: 300px;
  padding: 5px;
  font-size: 14px;
}

.edit-form .button-group {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 10px;
}

.edit-form button {
  padding: 6px 12px;
  font-size: 14px;
  margin: 0 5px;
}

.position-form-button-group {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: -5px;
}

.edit-form-button-save {
  background-color: rgba(30, 143, 255, 0.795);
  color: gainsboro;
}

.edit-form-button-cancel {
  background-color: #fc1e1e8a;
  color: gainsboro;
}

.edit-form button:hover {
  color: #ffffff; /* Change to your desired hover color */
  background-color: #3b3b3b; 
}
