/* Add to ensure no scrolling */
body {
  overflow-x: hidden;
}

.ticker-container {
  overflow: hidden;
  white-space: nowrap;
  background-color: #141414;
  padding: 10px;
  padding-top: 25px;
  padding-bottom: 30px;
  font-size: 16px;
  color: #333;
  width: 100%;
  position: relative; /* Add position relative */
}

/* Add gradient fade on left and right */
.ticker-container::before,
.ticker-container::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 30%; /* Adjust the width of the gradient fade as needed */
  background: linear-gradient(to left, rgba(20, 20, 20, 0), #141414);
  pointer-events: none; /* Ensure the gradient doesn't interfere with pointer events */
  z-index: 1; /* Ensure the gradient is above the ticker content */
}

.ticker-container::before {
  left: 0;
}

.ticker-container::after {
  right: 0;
  background: linear-gradient(to right, rgba(20, 20, 20, 0), #141414);
}

.ticker-content {
  display: inline-block;
  animation: ticker-scroll 15s linear infinite;
  will-change: transform;
  position: relative; /* Add position relative */
  z-index: 0; /* Ensure the ticker content is behind the gradient */
  max-width: 100%; /* Ensure content doesn't exceed container */
}

.ticker-content.paused {
  animation-play-state: paused;
}

.ticker-item {
  margin-right: 1.5rem;
  font-weight: bold;
  position: relative; /* Add position relative */
  z-index: 1; /* Ensure the ticker items are above the gradient */
}

@media (max-width: 768px) {
  .ticker-container {
    font-size: 14px;
  }

  .ticker-item {
    margin-right: 1rem;
  }

  .ticker-container::before,
  .ticker-container::after {
    width: 40%; /* Adjust the width of the gradient fade as needed for smaller screens */
  }
}

@keyframes ticker-scroll {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%); /* Ensure it scrolls completely */
  }
}
